import initialState from './initialState';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  state: initialState,
  namespaced: true,
  actions,
  getters,
  mutations
};
