import sanity from '@/infrastructure/sanity/sanity';
import QUERY_PRIVACY_POLICY from './queries';
import { SET_PRIVACY_POLICY, SET_PRIVACY_POLICY_LOADING, SET_PRIVACY_POLICY_ERROR } from './types';

export default {
  fetchPrivacyPolicy: async (context) => {
    context.commit(SET_PRIVACY_POLICY_LOADING, true);
    context.commit(SET_PRIVACY_POLICY_ERROR, { description: null, isError: false, code: null });
    sanity.fetch(QUERY_PRIVACY_POLICY).then(
      (coaches) => {
        context.commit(SET_PRIVACY_POLICY, coaches);
      },
      (error) => {
        context.commit(SET_PRIVACY_POLICY_ERROR, {
          description: error.details.description,
          code: error.response.statusCode,
          isError: true,
          query: 'fetchPrivacyPolicy'
        });
      }
    );
    context.commit(SET_PRIVACY_POLICY_LOADING, false);
  }
};
