<template>
  <div class="h-full w-screen bg-black">
    <LoadingState v-if="getPrivacyPolicyIsLoading" />
    <ErrorState
      v-else-if="getPrivacyPolicyIsError"
      :error="getPrivacyPolicyError"
      :retry="fetchPrivacyPolicy"
    />
    <Container
      :class="[
        'py-32',
        'flex flex-col items-center justify-between',
        'bg-transparent bg-cover bg-no-repeat bg-center',
      ]"
    >
      <BlogBlocks :content="privacyPolicyContent" />
    </Container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import privacyPolicyStore from '@/infrastructure/store/modules/privacyPolicy/';

import { ErrorState, LoadingState } from '@/pages/shared/index';
import Container from '@/components/atoms/Frames/Container.vue';
import BlogBlocks from '@/components/organisms/BlogBlocks/BlogBlocks.vue';

export default {
  name: 'PrivacyPolicyPage',
  components: {
    ErrorState,
    LoadingState,
    Container,
    BlogBlocks
  },
  computed: {
    ...mapGetters('privacyPolicy', [
      'getPrivacyPolicy',
      'getPrivacyPolicyError',
      'getPrivacyPolicyIsError',
      'getPrivacyPolicyIsLoading'
    ]),
    privacyPolicyContent() {
      return this.getPrivacyPolicy?.body || [];
    }
  },
  async created() {
    this.$store.registerModule('privacyPolicy', privacyPolicyStore);
    await this.fetchPrivacyPolicy();
  },
  beforeDestroy() {
    this.$store.unregisterModule('privacyPolicy', privacyPolicyStore);
  },
  methods: {
    ...mapActions('privacyPolicy', [ 'fetchPrivacyPolicy' ])
  }
};
</script>
