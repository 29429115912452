import { SET_PRIVACY_POLICY, SET_PRIVACY_POLICY_LOADING, SET_PRIVACY_POLICY_ERROR } from './types';

export default {
  [SET_PRIVACY_POLICY](state, privacyPolicy) {
    state.privacyPolicy = privacyPolicy;
  },
  [SET_PRIVACY_POLICY_LOADING](state, isLoading) {
    state.status.isLoading = isLoading;
  },
  [SET_PRIVACY_POLICY_ERROR](state, { description, code, isError, query }) {
    state.status.isError = isError;
    state.status.error.code = code;
    state.status.error.query = query;
    state.status.error.description = description;
  }
};
